<template>
  <div>
    <div class="news">
      <div class="container">
        <img :src="newsDetail.imgLink" alt="news" class="news__img" />
        <h1 class="news__title">{{ newsDetail.title }}</h1>
        <hr class="news__hr" />
        <p class="news__description" v-html="newsDetail.description"></p>
        <div class="news__date">
          <div class="icon-calendar"></div>
          {{ newsDetail.date }}
        </div>
        <hr class="news__hr" />
      </div>
    </div>
    <div class="container">
      <div class="news__more">
        {{ $t("news.readMore") }}
        <ul class="news__list">
          <li v-for="(n, index) in news" :key="index">
            <a href="/" @click.prevent="changeNews(n.slug)">{{ n.title }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      newsDetail: null,
    };
  },
  computed: {
    ...mapGetters(["news"]),
  },
  created() {
    this.setNews();
  },
  methods: {
    setNews() {
      this.news.forEach((e) => {
        if (e.slug == this.$route.params.slug) {
          this.newsDetail = e;
        }
      });
    },
    changeNews(slug) {
      if (this.$route.params.slug === slug) {
        return;
      } else {
        this.setNews();
        this.$router.push({ path: `/news/${slug}` });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.icon-calendar {
  width: 18px;
  height: 16px;
  background-image: url("../assets/icons/calendar_today.svg");
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 8px;
}
.news {
  padding: 50px 0 20px 0;
  background-color: #ffffff;
  &__more {
    padding: 25px 0;
  }
  &__list {
    list-style-type: none;
    margin: 15px 0;
    li {
      margin: 10px 0;
      a {
        font-size: 17px;
        line-height: 20px;
        font-style: italic;
      }
    }
  }
  &__date {
    display: flex;
    align-items: center;
    margin: 20px 0;
    font-size: 15px;
    line-height: 18px;
    color: #202020;
  }
  &__hr {
    max-width: 800px;
    margin: 0 auto 25px 0;
    border-top: 2px solid #202020;
  }
  &__img {
    width: 625px;
  }
  &__title {
    font-weight: 500;
    font-size: 35px;
    line-height: 41px;
    color: #202020;
    padding-left: 16px;
    border-left: 5px solid #e66e00;
    margin: 30px 0;
  }
  &__description {
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    max-width: 781px;
  }
}

@media (max-width: 768px) {
  .news {
    padding-top: 120px;
    &__list {
      margin: 10px 0;
      li {
        a {
          font-size: 15px;
        }
      }
    }
    &__hr {
      margin-bottom: 15px;
    }
    &__title {
      font-size: 20px;
      line-height: 20px;
    }
    &__description {
      font-size: 14px;
      line-height: 18px;
    }
  }
  .news__img {
    width: 100%;
  }
}
</style>
